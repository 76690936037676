<template>
  <v-container fluid class="content primary">
    <div v-if="dispdoc" row wrap flat class="primary">
      <v-row class="primary">
        <v-col class="ml-7" cols="11" sm="11" md="11" lg="9" xl="3">
          <v-row class="primary">
            <h1 class="mb-3 info--text" v-html="curdoc.title"></h1>
            <v-spacer></v-spacer>
            <v-btn large text color="accent" @click="dispdoc = false">
              <v-icon color="info" large left>close</v-icon>
              <span class="info--text title text-lowercase">Close</span>
            </v-btn>
          </v-row>
          <hr class="accent thick mb-4 mr-5" />
          <div
            class="pl-5 pr-5 pa-5 black--text ml-2 ck-content"
            v-html="curdoc.content"
          ></div>
        </v-col>
        <v-col cols="11" sm="11" md="11" lg="8" xl="8">
          <!-- Slideshow container -->
          <div class="ml-10">
            <v-carousel show-arrows height="70%" class="mb-10">
              <div v-for="item in mediaArray" :key="item">
                <v-carousel-item>
                  <v-img
                    v-if="item.split('.').pop() == 'jpg'"
                    lazy-src="https://vsgpartners.com/images/lazy.gif"
                    :src="picurl + item"
                  ></v-img>
                  <video
                    v-if="item.split('.').pop() == 'mp4'"
                    controls
                    height="auto"
                    preload="metadata"
                  >
                    <source
                      :src="picurl + item"
                      frameborder="0"
                      allow="encrypted-media"
                      allowfullscreen
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </v-carousel-item>
              </div>
            </v-carousel>
          </div>
        </v-col>
      </v-row>
    </div>
    <hr v-if="!dispdoc" class="accent thick mb-4 mr-5" />
    <div v-if="!dispdoc">
      <v-row cols="12" no-gutters wrap>
        <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="mb-5">
          <v-card
            v-for="item in front"
            :key="item.id"
            outlined
            color="primary"
            class="mr-7"
          >
            <v-card-text class="px-0">
              <h1 class="black--text ml-3">{{ item.title }}</h1>
              <v-row>
                <div
                  class="text-wrap ml-2 ck-content"
                  v-html="item.content"
                ></div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="pl-3">
          <v-card
            class="column_wrapper primary mb-5 mr-3"
            text
            v-for="project in myProjects"
            :key="project.title"
            @click="editDoc(project.id)"
          >
            <div>
              <h1
                class="
                  info--text
                  font-italic font-weight-medium
                  pl-0
                  pb-2
                  ml-3
                  truncate
                "
                v-if="project.status == 'publish'"
              >
                {{ project.title }}
              </h1>
              <img
                v-if="project.pic && project.status == 'publish'"
                :src="picurl + project.pic"
                position="top center"
                width="400"
                height="400"
                class="ml-3 mr-3"
              />
              <!--   <v-divider
                role="presentation"
                class="mt-5 mr-3 ml-3 accent"
              ></v-divider>
              <p
                class="localp pl-0 mt-3 ml-3"
                v-if="project.status == 'publish'"
              >
                {{ project.due | moment("dddd, MMMM Do, YYYY") }}
              </p>
              <img
                class="pa-0 ml-2 line"
                src="https://vsgpartners.com/images/line.png"
              /> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { shared } from "../mixins/shared";

export default {
  mixins: [shared],
  data() {
    return {
      items: ["save", "publish"],
      values: ["save", "publish"],
      projects: [],
      dispdoc: false,
      addimage: false,
      picurl: "https://vsgpartners.com/images/",
      refresh: 0,
      curdoc: [],
      mediaArray: [],
      imageArray: [],
      videoArray: [],
      mediaLength: 2,
      imageLength: 2,
      videoLength: 2,
      slideIndex: 1,
      front: []
    };
  },
  async created() {
    var url = "https://vsgpartners.com/data.php";
    var formdata = {
      action: "displayd"
    };
    await this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain"
        }
      })
      .then(res => {
        const data = res.data;
        this.projects = data;
        this.getFront();
      })
      .catch(error => alert(error));
  },
  computed: {
    myProjects() {
      return this.projects.filter(project => {
        return project.status === "publish";
      });
    }
  },
  methods: {
    editDoc(id) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].id === id) {
          this.curdoc.title = this.projects[i].title;
          this.curdoc.content = this.projects[i].content;
          this.curdoc.pic = this.projects[i].pic;
          this.curdoc.media = this.projects[i].media;
          this.mediaArray = this.curdoc.media.split(", ");
          this.imageLength = this.imageArray.length;
          this.videoLength = this.videoArray.length;
          this.mediaLength = this.mediaArray.length;
          this.dispdoc = true;
        }
      }
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  max-width: 50%;
  float: right;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.videoContainer {
  max-width: 100%;
  height: auto;
  float: left;
}

video {
  width: 100%;
  height: 100%;
}

[v-cloak] {
  display: none;
}

.column_wrapper {
  float: left;
}

.column_wrapper:hover {
  padding-left: 5px;
  box-shadow: 0 0px 0px red, 0 5px 5px red;
  cursor: pointer;
}

.truncate {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
  font-weight: 300;
  line-height: 30px;
  font-size: 20px;
  margin: 0;
  resize: horizontal;
  color: #4d4d4d;
}

.localp {
  font-weight: 400;
  line-height: 18px;
  font-size: 13px;
  color: #4d4d4d;
  margin: 0;
}

img.line {
  width: 300px;
}

hr.thick {
  border: 2px solid;
}

.edge {
  border-left: double black;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

@keyframes slide {
  from {
    left: 100%;
    transform: translate(0, 0);
  }
  to {
    left: -100%;
    transform: translate(-100%, 0);
  }
}
@-webkit-keyframes slide {
  from {
    left: 100%;
    transform: translate(0, 0);
  }
  to {
    left: -100%;
    transform: translate(-100%, 0);
  }
}

.marquee {
  color: red;
  top: 0;
  background: #eae7dc;
  width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
  vertical-align: text-top;
}

.text {
  position: absolute;
  white-space: nowrap;
  font-size: 16px;
  animation-name: slide;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide;
  -webkit-animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
</style>
