import Vue from "vue";
import VueRouter from "vue-router";

const Dashboard = () => import("../views/Dashboard.vue");
const Photologs = () => import("../views/Photologs.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "photologs",
    component: Photologs
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
