<template>
  <nav>
    <v-card>
      <v-app-bar fixed dense color="black" dark>
        <template v-slot:extension>
          <v-tabs
            active-class="select"
            color="secondary"
            mobile-breakpoint="1000"
            class="mb-5 hidden-md-and-down"
          >
            <v-tab
              class="ml-5 mb-5"
              v-for="link in links"
              :key="link.text"
              router
              :to="link.route"
            >
              <span class="menutop">{{ link.text }}</span>
            </v-tab>
          </v-tabs>
          <v-menu
            v-model="value"
            :absolute="absolute"
            :open-on-hover="openOnHover"
            :close-on-click="closeOnClick"
            :close-on-content-click="closeOnContentClick"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="red--text hidden-lg-and-up mb-10"
                color="primary"
                dark
                small
                v-bind="attrs"
                v-on="on"
                >Menu</v-btn
              >
            </template>
            <v-list>
              <v-list-item
                class="black"
                v-for="link in links"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-title class="primary--text">{{
                  link.text
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>
    </v-card>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      absolute: false,
      openOnHover: true,
      value: false,
      closeOnClick: true,
      closeOnContentClick: true,
      dash: 0,
      links: [],
      snackbar: false
    };
  },
  async created() {
    var url = "https://vsgpartners.com/data.php";
    var formdata = {
      action: "dash"
    };
    await this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain"
        }
      })
      .then(res => {
        const data = res.data;
        this.dash = data[0].dash;
        if (this.dash == 0) {
          this.links = [{ text: "Photologs", route: "/" }];
        } else {
          this.links = [
            { text: "Photologs", route: "/" },
            { text: "Dashboard", route: "/dashboard" }
          ];
        }
        console.log(this.links);
      })
      .catch(error => alert(error));
  }
};
</script>

<style scoped>
.menutop {
  font-family: "Playfair Display", serif;
  line-height: 1.5;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 2rem;
}
</style>
