import router from "@/router/index.js";

export const shared = {
  methods: {
    forceRerender() {
      this.refresh += 1;
    },
    sendOff(where) {
      router.push(where);
    },
    sortBy(prop) {
      if (prop == "title") {
        this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
      } 
      if(prop == "due") {
        this.projects.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
      if(prop == "slot") {
        this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
      }
    },
    getDocs(which) {
      var url = "https://vsgpartners.com/data.php";
      var formdata = {
        action: which,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.projects = data;
        })
        .catch((error) => alert(error));
      this.forceRerender();
    },
    getFront() {
      var url = "https://vsgpartners.com/data.php";
      var formdata = {
        action: "front",
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.front = data;
        })
        .catch((error) => alert(error));
    },
  },
};
