<template>
  <v-container fluid width="70%" class="ml-5 content">
    <v-row v-if="showtop">
      <v-tooltip color="success" top>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="sortBy('title')"
            v-on="on"
            slot="activator"
          >
            <v-icon small left>folder</v-icon>
            <span class="accent--text caption text-lowercase">By title</span>
          </v-btn>
        </template>
        <span>Sort by title</span>
      </v-tooltip>
      <v-tooltip color="success" top>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="sortBy('due')"
            v-on="on"
            slot="activator"
          >
            <v-icon small left>person</v-icon>
            <span class="accent--text caption text-lowercase">By date</span>
          </v-btn>
        </template>
        <span>Sort by title</span>
      </v-tooltip>
      <v-tooltip color="success" top>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="sortBy('slot')"
            v-on="on"
            slot="activator"
          >
            <v-icon small left>person</v-icon>
            <span class="accent--text caption text-lowercase">By slot</span>
          </v-btn>
        </template>
        <span>Sort by title</span>
      </v-tooltip>
      <v-tooltip color="success" top>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="addDoc()"
            v-on="on"
            slot="activator"
          >
            <v-icon small left>add_box</v-icon>
            <span class="accent--text text-lowercase subtitle-1"
              >Add new photolog</span
            >
          </v-btn>
        </template>
        <span>Add new Photolog</span>
      </v-tooltip>
    </v-row>
    <v-col v-if="addimage" xs12 sm3 md6 class="mb-4">
      <div>
        <input
          class="ml-3 subtitle-1"
          type="file"
          id="file"
          ref="file"
          v-on:change="onChangeFileUpload()"
        />
        <v-btn top color="amber" class="subtitle-1" v-on:click="submitFile()"
          >Upload</v-btn
        >
      </div>
    </v-col>
    <v-col xs12 sm3 md8 class="mb-4">
      <v-card class="primary" v-if="editdoc || adddoc">
        <v-card-title v-if="editdoc">
          <h2 class="accent--text">Edit Photolog</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="accent--text">Add New Photolog</h2>
        </v-card-title>
        <v-card-text>
          <ValidationObserver v-slot="{ invalid }">
            <v-form class="px-3" ref="form" @submit.prevent="onSubmit">
              <ValidationProvider
                rules="required"
                name="title"
                v-slot="{ errors }"
              >
                <v-text-field
                  color="accent"
                  class="photo-title"
                  label="Title"
                  prepend-icon="folder"
                  v-model.lazy="curdoc.title"
                ></v-text-field>
                <span class="accent--text">{{ errors[0] }}</span>
              </ValidationProvider>
              <v-text-field
                color="accent"
                class="photo-title"
                label="Photo"
                prepend-icon="photo"
                v-model.lazy="curdoc.pic"
              ></v-text-field>
              <v-menu v-model="menu" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    rules="required"
                    name="due"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="accent"
                      label="Date"
                      prepend-icon="calendar_today"
                      clearable
                      v-on="on"
                      v-model.lazy="curdoc.due"
                      readonly
                      class="blog-title"
                    ></v-text-field>
                    <span class="accent--text">{{ errors[0] }}</span>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="curdoc.due"
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                color="accent"
                v-model.lazy="curdoc.status"
                :items="items"
                chips
                prepend-icon="publish"
                label="Status"
                class="photo-title"
              ></v-select>
              <v-text-field
                color="accent"
                class="blog-title"
                label="slot"
                prepend-icon="folder"
                type="number"
                v-model.lazy="curdoc.slot"
              ></v-text-field>
              <ValidationProvider
                rules="required"
                name="media"
                v-slot="{ errors }"
              >
                <v-text-field
                  color="accent"
                  class="photo-title"
                  label="Media"
                  prepend-icon="list_alt"
                  v-model.lazy="curdoc.media"
                ></v-text-field>
                <span class="accent--text">{{ errors[0] }}</span>
              </ValidationProvider>
              <ckeditor
                :editor="editor"
                v-model.lazy="curdoc.content"
                :config="editorConfig"
                class="ck-content"
              ></ckeditor>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                text
                @click="submit"
                class="black white--text mx-0 mt-3 mr-3"
                :disabled="invalid"
                >Save Photolog</v-btn
              >
              <v-btn
                text
                @click="
                  editdoc = false;
                  adddoc = false;
                  showtop = true;
                  dispdoc = true;
                  curdoc = [];
                "
                class="black white--text mx-0 mt-3 mr-3"
                >Cancel</v-btn
              >
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-col>
    <v-row v-if="upimg">
      <v-col>
        <input
          class="ml-3 subtitle-1"
          type="file"
          id="file"
          ref="file"
          v-on:change="onChangeFileUpload()"
        />
        <v-btn top color="amber" class="subtitle-1" v-on:click="submitForm()"
          >Upload</v-btn
        >
        <v-btn
          top
          color="amber"
          class="subtitle-1 ml-2"
          v-on:click="upimg = false"
          >Cancel</v-btn
        >
      </v-col>
    </v-row>
    <div :key="refresh" class="mytable">
      <table v-if="dispdoc">
        <tbody>
          <tr>
            <th class="title accent--text font-weight-bold">Title</th>
            <th class="title accent--text font-weight-bold">Photo</th>
            <th class="title accent--text font-weight-bold">Image</th>
            <th class="title accent--text font-weight-bold">Date</th>
            <th class="title accent--text font-weight-bold">Status</th>
            <th class="title accent--text font-weight-bold">Slot</th>
            <th class="title accent--text font-weight-bold media">Media</th>
            <th class="title accent--text font-weight-bold">Actions</th>
          </tr>
          <tr
            v-for="project in projects"
            :key="project.id"
            row
            wrap
            :class="`pa-2 project ${project.status}`"
          >
            <td class="photo-title">{{ project.title }}</td>
            <td class="photo-title">{{ project.pic }}</td>
            <td>
              <img
                v-if="project.pic"
                :src="picurl + project.pic"
                position="top center"
                width="75"
                height="75"
                class="ml-3 mr-3 img-click"
              />
            </td>
            <td class="photo-title">
              {{ project.due | moment("MMMM Do, YYYY") }}
            </td>
            <td>
              <v-chip
                small
                :class="`${project.status} white--text my-2 caption`"
                >{{ project.status }}</v-chip
              >
            </td>
            <td class="photo-title">{{ project.slot }}</td>
            <td class="photo-title">{{ project.media }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="mr-4 material-icons"
                    @click="editDoc(project.id)"
                    >edit</v-icon
                  >
                </template>
                <span>Edit Item</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons"
                    @click="delDoc(project.id)"
                    >delete</v-icon
                  >
                </template>
                <span>Delete Item</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons"
                    @click="loadImg(project.id)"
                    >image</v-icon
                  >
                </template>
                <span>Add Photolog</span>
              </v-tooltip>
              <v-snackbar
                v-model="snackbar"
                :timeout="3000"
                top
                color="success"
              >
                <span>You deleted a item.</span>
                <v-btn color="accent" text @click="snackbar = false"
                  >Close</v-btn
                >
              </v-snackbar>
              <v-snackbar
                v-model="snackbar2"
                :timeout="3000"
                top
                color="success"
              >
                <span>You saved the item.</span>
                <v-btn color="accent" text @click="snackbar = false"
                  >Close</v-btn
                >
              </v-snackbar>
              <v-snackbar
                v-model="snackbar3"
                :timeout="3000"
                top
                color="success"
              >
                <span>You uploaded the file.</span>
                <v-btn color="accent" text @click="snackbar = false"
                  >Close</v-btn
                >
              </v-snackbar>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { shared } from "../mixins/shared";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import LineHeight from "ckeditor5-line-height-plugin/src/lineheight";
import List from "@ckeditor/ckeditor5-list/src/list";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";

export default {
  mixins: [shared],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      refresh: 0,
      items: ["save", "publish", "front"],
      values: ["save", "publish", "front"],
      projects: [],
      snackbar: false,
      snackbar2: false,
      snackbar3: false,
      editdoc: false,
      mediaArray: "",
      adddoc: false,
      dispdoc: true,
      getarray: false,
      showtop: true,
      upimg: false,
      addimage: false,
      curdoc: [],
      curid: 0,
      menu: false,
      picurl: "https://vsgpartners.com/images/",

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          Underline,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          ImageUpload,
          List,
          // EasyImage,
          LineHeight,
          SimpleUploadAdapter,
          Heading,
          Alignment,
          Font,
          Strikethrough,
          Subscript,
          Superscript,
          BlockQuote,
          Indent,
          IndentBlock,
        ],
        indentBlock: {
          classes: [
            "custom-block-indent-a", // First step - smallest indentation.
            "custom-block-indent-b",
            "custom-block-indent-c", // Last step - biggest indentation.
          ],
        },
        lineHeight: {
          // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
          options: [0.5, 1, 1.5, 1.75, 2, 2.5],
        },
        image: {
          resizeUnit: "px",
          // You need to configure the image toolbar, too, so it uses the new style buttons.
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],

          styles: [
            "alignCenter",

            // This represents an image aligned to the left.
            "alignLeft",

            // This represents an image aligned to the right.
            "alignRight",
          ],
        },
        alignment: {
          options: ["left", "right"],
        },
        fontSize: {
          options: ["tiny", "default", "big"],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "numberedList",
            "bulletedList",
            "alignment",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "|",
            "link",
            "|",
            "imageUpload",
            "heading",
            "|",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "lineHeight",
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://vsgpartners.com/upload.php",

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSFR-Token",
            Authorization: "Bearer <JSON Web Token>",
          },
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.curdoc.title) {
        var url = "https://vsgpartners.com/data.php";
        var action;
        if (this.editdoc) {
          action = "submit";
        } else {
          action = "add";
        }
        if (
          typeof this.curdoc.status == "undefined" ||
          this.curdoc.status === ""
        ) {
          this.curdoc.status = "save";
        }
        var formdata = {
          action: action,
          id: this.curdoc.id,
          title: this.curdoc.title,
          status: this.curdoc.status,
          pic: this.curdoc.pic,
          due: this.curdoc.due,
          media: this.curdoc.media,
          slot: this.curdoc.slot,
          content: this.curdoc.content,
        };
        this.axios.post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        });
        this.snackbar2 = true;
        this.editdoc = false;
        this.adddoc = false;
        this.curdoc = [];
        this.dispdoc = true;
        this.showtop = true;
        this.getDocs("displayd");
      }
    },
    editDoc(id) {
      var url = "https://vsgpartners.com/data.php";
      var formdata = {
        action: "edit",
        id: id,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.curdoc = data[0];
          this.mediaArray = this.curdoc.media.split(", ");
          this.editdoc = true;
          this.showtop = false;
          this.dispdoc = false;
        })
        .catch((error) => alert(error));
    },
    addDoc() {
      this.adddoc = true;
      this.showtop = false;
      this.curdoc.status = "save";
    },
    delDoc(id) {
      if (confirm("Do you really want to delete?")) {
        var url = "https://vsgpartners.com/data.php";
        var formdata = {
          action: "del",
          id: id,
        };
        this.axios.post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        });
        this.snackbar = true;
        this.getDocs("displayd");
      }
    },
    submitForm() {
      var check = document.getElementById("file");
      if (check.value == "") {
        alert("Must choose a file");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post("https://vsgpartners.com/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.storeImg(this.file.name);
    },
    submitForm2() {
      var check = document.getElementById("file");
      if (check.value == "") {
        alert("Must choose a file");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post("https://vsgpartners.com/api2.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.storeImg2(this.file.name);
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post("https://vsgpartners.com/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
          console.log(data.data);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    loadImg(id) {
      this.curid = id;
      this.upimg = true;
    },
    storeImg(img) {
      var url = "https://vsgpartners.com/data.php";
      var formdata = {
        action: "upload",
        id: this.curid,
        img: img,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then()
        .catch((error) => alert(error));
      this.snackbar3 = true;
      this.getDocs("displayd");
    },
  },
  created() {
    var url = "https://vsgpartners.com/data.php";
    var formdata = {
      action: "displayd",
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.projects = data;
      })
      .catch((error) => alert(error));
  },
};
</script>

<style scoped>
/* p {
  clear: both;
} */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 95%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: left;
  padding: 5px 10px 5px 10px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.numbers {
  text-align: right;
}

.mytable {
  overflow: auto;
}

.media {
  width: 25%;
}
</style>
