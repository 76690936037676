import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { ValidationProvider, extend } from "vee-validate";
import "./vee-validate";
import CKEditor from "@ckeditor/ckeditor5-vue";

Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"));
Vue.use(CKEditor);
Vue.config.productionTip = false;

extend("datecheck", (value) => {
  if (value.substring(0, 2) !== "20") {
    return "Must be an actual date";
  }
  value = new Date(value);
  value = value.toISOString().split("T")[0];
  var today = new Date();
  today.setDate(today.getDate() - 1);
  today = today.toISOString().split("T")[0];

  if (value >= today) {
    return true;
  }

  return "This date must today or later";
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
