<template>
  <v-app>
    <Navbar />
    <v-main class="primary">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",
  components: { Navbar },
  data: () => ({
    logged: false,
    projects: [],
    password: "",
  }),
  methods: {},
};
</script>

<style lang="css">
html {
  width: 100%;
}

body {
  width: 100%;
}

.v-carousel__controls {
  text-align: center !important;
  height: 20px !important;
  background-color: transparent !important;
  bottom: 7%;
}

.v-btn__content {
  color: gold !important;
}

button.v-item--active.v-btn--active.v-btn--icon span > i {
  color: red !important;
}

.content {
  margin-top: 150px !important;
  margin-left: 10px !important;
}

.ck-editor__editable {
  min-height: 500px;
}

.project.save {
  border-left: 6px solid #e98074;
}
.project.publish {
  border-left: 6px solid #e85a4f;
}
.project.front {
  border-left: 6px solid #d8c3a5;
}
.v-chip.save {
  background: #e98074 !important;
}
.v-chip.publish {
  background: #e85a4f !important;
}
.v-chip.front {
  background: #d8c3a5 !important;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.custom-block-indent-a {
  margin-left: 2%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}
.side-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
/* p {
  clear: left !important;
} */
h1,
h2,
h3,
.title {
  font-family: "Alegreya Sans", sans-serif;
  letter-spacing: 1px;
  line-height: 160%;
}
p,
li {
  font-family: "Literata", serif;
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 150%;
}

.ck-content p,
.ck-content li {
  font-family: "Literata", serif;
  color: #202020;
  font-size: 1.4rem;
  letter-spacing: 1px;
  line-height: 160%;
}

li {
  margin-bottom: 10px;
}

.img-click:hover {
  color: #424242;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 1;
  transform: scale(3);
  -ms-transform: scale(3); /* IE 9 */
  -webkit-transform: scale(3); /* Safari and Chrome */
  z-index: 10;
}

/* @media only screen and (max-width: 600px) {
  img,
  figure {
    max-width: 250px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  img,
  figure {
    max-width: 350px !important;
  }
} */
</style>
