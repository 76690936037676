import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify, {
  iconfont: "md",
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#EAE7DC",
        success: "#8E8D8A",
        secondary: "#DCDFEA",
        accent: "#E85A4F",
        info: "#CC6600",
        error: "#F83E70",
      },
    },
  },
});
